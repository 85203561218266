<template>
  <!-- eslint-disable -->
  <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g class="fill" transform="translate(-120.000000, -4000.000000)" fill="#000000">
        <g transform="translate(0.000000, 3920.000000)">
          <g transform="translate(80.000000, 80.000000)">
            <g transform="translate(40.000000, 0.000000)">
              <path d="M5.29289322,8.29289322 C5.65337718,7.93240926 6.22060824,7.90467972 6.61289944,8.20970461 L6.70710678,8.29289322 L12,13.585 L17.2928932,8.29289322 C17.6533772,7.93240926 18.2206082,7.90467972 18.6128994,8.20970461 L18.7071068,8.29289322 C19.0675907,8.65337718 19.0953203,9.22060824 18.7902954,9.61289944 L18.7071068,9.70710678 L12.7071068,15.7071068 C12.3466228,16.0675907 11.7793918,16.0953203 11.3871006,15.7902954 L11.2928932,15.7071068 L5.29289322,9.70710678 C4.90236893,9.31658249 4.90236893,8.68341751 5.29289322,8.29289322 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
  <!-- eslint-enable -->
</template>

<script>
export default {
  name: 'ChevronBottomSvg',
};
</script>
