<template>
  <div class="header">
    <div class="custom-container">
      <router-link to="/" class="header-logo">
        <short-logo-svg />
        <logo-svg />
      </router-link>

      <div class="header-menu">
        <router-link
          :to="{ name: 'Searches' }"
          class="header-menu__item"
        >
          Search Candidates
        </router-link>

        <router-link
          :to="{ name: 'MyVault' }"
          class="header-menu__item"
        >
          MyVault
        </router-link>
      </div>

      <div class="header-user">
        <div class="header-user__name">
          <span>{{ authUserName }}</span>
          <chevron-bottom-svg />
        </div>

        <div class="header-user__menu">
          <router-link
            :to="{ name: 'EmployerProfile' }"
            class="header-user__menu-item"
          >
            <settings-svg />
            <span>Profile</span>
          </router-link>

          <router-link
            :to="{ name: 'SignOut', params: { type: 'employer' } }"
            class="header-user__menu-item"
          >
            <log-out-svg />
            <span>Sign out</span>
          </router-link>
        </div>
      </div>

      <div class="header-menu-button d-lg-none" @click="openMobileMenu">
        <menu-button-svg />
      </div>
    </div>
  </div>
</template>

<script>
import LogoSvg from '@/components/svg/LogoSvg.vue';
import ShortLogoSvg from '@/components/svg/ShortLogoSvg.vue';
import ChevronBottomSvg from '@/components/svg/ChevronBottomSvg.vue';
import SettingsSvg from '@/components/svg/SettingsSvg.vue';
import LogOutSvg from '@/components/svg/LogOutSvg.vue';
import MenuButtonSvg from '@/components/svg/MenuButtonSvg.vue';

import { mapGetters } from 'vuex';

export default {
  name: 'AgencyHeader',
  components: {
    LogoSvg,
    ShortLogoSvg,
    ChevronBottomSvg,
    SettingsSvg,
    LogOutSvg,
    MenuButtonSvg,
  },
  computed: {
    ...mapGetters({
      authUserName: 'auth/getAuthUserName',
    }),
  },
  methods: {
    openMobileMenu() {
      this.$store.commit('setMobileMenuStatus', true);
    },
  },
};
</script>
